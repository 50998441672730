@import 'src/styles/tokens';

.dynamic-form .mat-form-field-type-mat-date-range-input .mat-form-field-infix {
  width: 340px;
  display: flex;
}

.dynamic-form .mat-date-range-input {
  width: calc(100% - 32px) !important;

  ~ .mat-datepicker-toggle {
    margin: -13px -38px -13px 0px;
  }
}

.dynamic-form_title-divider {
  border-top-color: $color-neutral-100 !important;
}

@use '@angular/material' as mat;
@import '~viewerjs/dist/viewer.css';
@import 'variables';

// Core
@import 'src/styles/primitives';

// Components
@import 'src/app/app.global';
@import 'src/app/components/card-default/card-default.global';
@import 'src/app/components/container-header/container-header.global';
@import 'src/app/components/companies-list/companies-list.global';
@import 'src/app/components/dialog-crude/dialog-crude.global';
@import 'src/app/components/dialog-reprovation/dialog-reprovation.global';
@import 'src/app/components/dialog-save/dialog-save.global.scss';
@import 'src/app/components/dynamic-form/dynamic-form.global.scss';
@import 'src/app/components/field-data-loader/field-data-loader.global.scss';
@import 'src/app/components/sick-note/informations/informations.global.scss';
@import 'src/app/components/form-components/form-components.global.scss';
@import 'src/app/components/sidebar/menu/menu.global';
@import 'src/app/pages/sick-note/validate-dialog/validate-dialog.global';
@import 'src/app/pages/general-instructions/general-instructions.global';
@import 'src/app/components/qr-code-secret-dialog/qr-code-secret-dialog.global';
@import 'src/app/components/file-comparison-dialog/file-comparison-dialog.global.scss';
@import 'src/app/components/sick-note/status-actions/status-actions.global.scss';

// Global mixin
@mixin closecare-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .color-primary {
    color: mat.get-color-from-palette($primary);
  }

  .color-accent {
    color: mat.get-color-from-palette($accent, default);
  }

  .bg-color-primary {
    background-color: mat.get-color-from-palette($primary);
  }

  .bg-color-accent {
    background-color: mat.get-color-from-palette($accent, default);
  }

  .bg-color-accent-dark {
    background-color: mat.get-color-from-palette($accent, darker);
  }

  html,
  body {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
  }

  body {
    margin: 0;
    font-family: 'Roboto', 'Helvetica Neue', sans-serif;
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }

  button {
    font-family: 'Roboto', 'Helvetica Neue', sans-serif;

    &:focus {
      outline: 0;
    }
  }

  .backdrop_overlay {
    background: rgba(0, 0, 0, 0.7);
  }

  .single-line {
    font-size: 14px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    min-width: 0;
  }

  .zero-padding-dialog .mat-dialog-container {
    padding: 0;
  }

  .closecare-logo {
    font-family: 'Comfortaa', sans-serif;
    font-weight: 200;
    color: white;
    font-size: 1.5em;
    line-height: 1;
  }

  a.closecare-logo {
    text-decoration: none;
  }

  .closecare-logo b {
    font-weight: 700;
  }

  .person-edit-dialog .mat-dialog-container {
    padding: 0;
  }

  .mat-tooltip {
    font-size: 12px;
  }

  .mat-button-toggle {
    // background-color: mat-color($primary);
    // color: mat-color($primary, default-contrast);
  }

  .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($primary) !important;

    .mat-button-toggle-label-content {
      color: white;
    }
  }

  .introjs-tooltip.custom-intro {
    background-color: mat.get-color-from-palette($primary, darker);
    min-width: 300px;
    max-width: 400px;

    .introjs-arrow.left {
      border-color: transparent mat.get-color-from-palette($primary, darker) transparent transparent;
    }

    .introjs-arrow.top {
      border-color: transparent transparent mat.get-color-from-palette($primary, darker);
    }
  }

  .mat-checkbox-layout {
    white-space: normal !important;
  }

  .btn-is-loading {
    animation: leftright 0.2s ease-out, mat-progress-spinner-linear-rotate 2s linear infinite !important;
  }

  ;

  snack-bar-container.error {
    background: #c62828;
  }

  .error .mat-simple-snackbar-action {
    color: #b3e5fc;
  }

  .no-padding .mat-horizontal-content-container {
    padding: 0 0 24px 0;
  }

  .stretch-stepper {
    display: flex;
    flex: 1;
    flex-direction: column;

    .mat-horizontal-content-container {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .mat-horizontal-stepper-content {
      display: flex;
    }
  }

  .align-start .mat-grid-tile .mat-figure {
    justify-content: flex-start;
    align-items: center;
  }

  tr.mat-row:hover {
    background: rgba(33, 150, 243, 0.04);
  }

  .centered-chips .mat-chip-list-wrapper {
    justify-content: center;
  }

  .mat-chip-list-wrapper input.mat-input-element,
  .mat-chip-list-wrapper .mat-standard-chip {
    padding: 0 10px;
    border-radius: 4px;
    font-size: 13px;
  }

  .mat-chip.mat-standard-chip .mat-chip-remove {
    opacity: 1;
  }

  .metabase {
    .mat-form-field-appearance-outline .mat-form-field-outline {
      color: mat.get-color-from-palette($primary);
    }

    .location-picker-option.mat-select-panel .mat-option {
      font-size: 0.875em;
    }
  }

  button.mat-raised-button,
  button.mat-stroked-button,
  button.mat-flat-button {
    border-radius: 8px;
    font-weight: normal;
  }

  .mat-stroked-button.mat-primary:not(.mat-button-disabled) {
    border-color: mat.get-color-from-palette($primary);
  }

  .mat-stroked-button.mat-warn:not(.mat-button-disabled) {
    border-color: mat.get-color-from-palette($warn);
  }

  .mat-stroked-button.light {
    border-color: white;
  }

  .mat-stroked-button.mat-accent {
    border-color: mat.get-color-from-palette($accent);
  }

  button .mat-icon:not(.bigger-icon) {
    font-size: 20px;
    height: 20px;
    min-width: 20px;
  }

  .mat-icon-only .mat-icon {
    line-height: 20px !important;
  }

  .primary-bg.mat-button-disabled {
    background: lightgrey;
  }

  .mat-tab-label-active .mat-tab-label-content {
    color: mat.get-color-from-palette($primary);
  }

  app-sick-notes-list-container {
    .mat-tab-header {
      padding: 0 40px;
      margin-bottom: 24px;
    }

    .mat-tab-label {
      height: 56px;
      justify-content: flex-start;
      padding: 0;
      margin-right: 32px;
      // min-width: 120px;
      width: 160px;
      opacity: 1;
      color: $app-grey;
    }

    mat-form-field.mat-form-field.mat-form-field-appearance-outline>div.mat-form-field-wrapper>div.mat-form-field-flex>div.mat-form-field-infix {
      padding: 0.4em 0
    }

    mat-form-field.mat-form-field.mat-form-field-appearance-outline>div.mat-form-field-wrapper>div.mat-form-field-flex>div.mat-form-field-infix>span.mat-form-field-label-wrapper {
      top: -1.5em;
    }

    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-1.1em) scale(.75);
      width: 133.33333%;
    }

    .mat-form-field-outline {
      // background: #EAEEF1;
      border-radius: 8px;
      border: 0;
    }

    .app-flex-tabs .mat-tab-body-wrapper {
      flex: 1 1 auto;
    }
  }

  .mat-radio-outer-circle {
    border-color: $app-grey;
  }

  .mat-radio-label-content {
    color: $app-grey;
  }

  .mat-radio-checked .mat-radio-label-content {
    color: mat.get-color-from-palette($app-primary)
  }

  .app-large-radio {
    .mat-radio-container {
      height: 30px;
      width: 30px;
    }

    .mat-radio-outer-circle {
      height: 30px;
      width: 30px;
    }

    .mat-radio-inner-circle {
      height: 30px;
      width: 30px;
    }

    .mat-radio-button .mat-radio-ripple {
      height: 60px;
      /*double of your required circle radius*/
      width: 60px;
      /*double of your required circle radius*/
      left: calc(50% - 30px);
      /*'30px'-same as your required circle radius*/
      top: calc(50% - 30px);
      /*'30px'-same as your required circle radius*/
    }

    .mat-radio-label-content {
      font-size: 16px;
    }
  }

  .font-light {
    color: $app-grey;
  }

  .mat-chip.small-chip {
    font-size: 11px;
    padding: 0 8px;
    min-height: 24px;
    margin-right: 4px;
    border-radius: 16px;
  }

  .alert-chip.mat-chip.mat-standard-chip.mat-chip-selected {
    background: $third-light;
    color: $app-text-color;
  }

  .mat-chip.mat-standard-chip.mat-warn {
    background: $warn-light;
    color: mat.get-color-from-palette($warn);
  }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-light {
    background: #00DCFA;
  }

  .mat-icon-button.small-circle-button {
    color: white;
    background: mat.get-color-from-palette($primary);
    width: 24px;
    height: 24px;
    line-height: 1;

    .mat-icon {
      width: 16px;
      height: 16px;
      line-height: 16px;
    }

    .material-icons {
      font-size: 16px;
    }
  }

  .spinner-backdrop {
    background: #ffffff88;
  }

  .mat-snack-bar-container {
    &.status-snackbar {
      max-width: 100% !important;
      border-radius: 8px;
      background: $app-text-color;
      color: white;
    }

    &.status-snackbar.error {
      background: #9F0000;
    }

    &.status-snackbar.ok {
      background: #006132;
    }

    &.update-snackbar .mat-button {
      color: white;
    }
  }

  .mat-sort-header-arrow {
    color: mat.get-color-from-palette($primary);
  }

  .round-icon.mat-icon {
    font-size: 12px;
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    background: #D1D2D3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}

.margin-center {
  margin: 0 auto;
}

.dialog__filter {
  // .mat-dialog-container {
  //   overflow: inherit;
  //   padding: 0;
  // }

  .ig {
    .mat-input-element {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .mat-select-arrow {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid $primary;
  }

  .mat-datepicker-toggle-default-icon {
    color: $primary;
  }
}

app-users-tabs {
  .mat-tab-header {
    padding: 0 32px;
  }

  .mat-badge-medium.mat-badge-above .mat-badge-content {
    margin-top: -11px;
    top: 50%;
  }
}

.button__action {
  min-width: 36px !important;
  padding: 0 !important;
}

.dialog {
  &__container {
    width: calc(100% + 40px);
    margin: 0 -20px;
    position: relative;
    flex: 1;

    section {
      margin-bottom: 32px;

      &:first-child {
        margin-top: 2px;
      }
    }

    .mat-form-field-infix {
      width: auto;
    }
  }

  &__scroll {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    overflow: auto;
    position: absolute;
  }
}

.dialog__save .mat-dialog-container {
  padding: 20px;
  position: relative;
  overflow: hidden;

  >* {
    height: 100%;
  }
}

.notification__item {
  width: calc(100% - 20px) !important;
  height: auto !important;
  border-radius: 6px;
  margin: 0 10px;

  &:hover {
    background: rgba(211, 211, 211, 0.21);

    .item__message {
      color: $primary;
    }
  }
}

app-users-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.single-select__list {
  .list__button {
    .mat-button-wrapper {
      white-space: normal;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &--selected .mat-button-focus-overlay {
      background-color: $primary;
      opacity: .1;
    }
  }
}

.main__scroll {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  flex: 1;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    transition: $time-200;
  }
}

.single-select__scroll {
  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    background: #D7DDE1;
    border-radius: 3px;

    &:hover {
      background: #ccc;
    }
  }
}

.arrow .mat-button-wrapper {
  padding: 0 !important;
}

.file {
  border: 1px solid $app-light-grey;
}

.file-wrapper {
  height: 100%;
  overflow: hidden;
  flex: 1;
  display: flex;
}

.select__loading {
  opacity: .3;
  pointer-events: none;
  cursor: default;
}

.select__loader {
  top: 50%;
  left: 50%;
  position: absolute !important;
  margin: -12px 0 0 -12px;
}

.input-no-margin {
  .mat-form-field {
    margin-bottom: -1.25em;
  }
}

.select-no-icon {
  .mat-select-arrow {
    border: none;
  }
}

.select-center {
  .mat-select-arrow {
    border: none;
  }

  .mat-form-field-type-mat-select .mat-form-field-label {
    width: 100%;
  }

  .mat-form-field-outline-gap {
    width: 60% !important;
  }

  .mat-form-field-outline-start {
    width: 20% !important;
  }

  mat-label {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.verify-button.mat-stroked-button {
  background-color: $app-green;

  .mat-button-wrapper {
    color: white;
  }

  &.mat-button-disabled {
    opacity: .26;
  }
}

.no-spaces .mat-form-field-wrapper {
  margin: 0 !important;
  padding: 0;
}

.label-value {
  width: 100%;

  &__icon {
    font-size: 14px;
    width: 14px !important;
    height: 14px !important;
  }

  &__label {
    color: $app-label;
    display: flex;
    gap: 6px;
  }

  &__value {
    color: $app-text-color !important;
    display: flex;
    justify-content: space-between;
  }

  &__value-detail {
    font-weight: 400;
  }
}

.color-danger {
  color: red;
}

.table__nodata {
  text-align: center;
  padding: 32px 0;
  margin: 0 auto;
}

.connected-dialog {
  .mat-dialog-container {
    background: transparent;
    box-shadow: none;
    padding: 32px;
  }
}

.category-select {
  &__label {
    font-size: 14px;
    color: $app-text-color;
    background: transparent;
    border: none;
    padding: 0;
    margin: 32px 0 16px 0;
    display: flex;
    align-items: center;

    &--no-margin-top {
      margin-top: 0;
    }
  }

  .mat-chip:hover {
    background: $color-neutral-40;
  }

  .mat-standard-chip.mat-chip-with-trailing-icon {
    padding: 8px;
    margin: 4px 8px;
  }

  tr {
    height: auto !important;
  }

  th,
  td {
    &:first-child {
      padding-left: 10px !important;
    }

    &:last-child {
      padding-right: 10px !important;
    }
  }

  th {
    padding: 8px 0 !important;
  }

  td {
    padding: 16px 0 !important;
  }

  .action-button {
    line-height: 28px;
    height: 28px;
    border-radius: 4px;
    padding: 0 12px;
  }
}

.default-list {
  &__chips {
    background: $color-neutral-05;
  }

  .mat-chip:hover {
    background: $color-neutral-40;
  }

  .mat-standard-chip.mat-chip-with-trailing-icon {
    padding: 8px;
    margin: 4px 8px;
  }

  tr {
    height: auto !important;
  }

  th,
  td {
    &:first-child {
      padding-left: 10px !important;
    }

    &:last-child {
      padding-right: 10px !important;
    }
  }

  th {
    padding: 8px 0 !important;
  }

  td {
    padding: 16px 0 !important;
  }

  .action-global {
    height: 40px;

    &:not(button) {
      margin-top: -6px;
    }

    .mat-form-field-appearance-outline {
      .mat-form-field-wrapper {
        padding: 0;
        margin: 0;
      }

      .mat-form-field-flex {
        padding: 0 0.8em;
      }

      .mat-form-field-outline-start {
        border-radius: 8px 0 0 8px;
      }

      .mat-form-field-outline-end {
        border-radius: 0 8px 8px 0;
      }

      .mat-form-field-prefix {
        top: 0.3em;
      }

      .mat-form-field-label {
        font-size: 14px;
        top: 1.6em;
      }

      &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
      .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
        transform: translateY(-1.2em) scale(0.75);
      }

      .mat-form-field-infix {
        width: 222px;
        padding-top: 0.38em;
        padding-bottom: 0.78em;
      }

      input.mat-input-element {
        font-size: 14px;
      }

      button {
        width: 20px !important;
        height: 20px !important;
      }
    }

    .mat-icon {
      font-size: 20px !important;
      line-height: 20px;
      height: 20px;
      width: 20px;

      &[matPrefix] {
        padding-right: 0.3em;
      }
    }

    .clear {
      position: absolute;
      top: -10px;
      right: 0;
    }
  }

  .action-button {
    line-height: 28px;
    height: 28px;
    border-radius: 4px;
    padding: 0 12px;
  }
}

.filter-select-wrapper {
  width: 144px !important;
}

.filter-select {
  margin-bottom: -1.25em !important;

  .mat-form-field-infix {
    padding: 0.4em 0 !important;
  }

  .mat-form-field-label-wrapper {
    top: -1.5em !important;
  }

  &.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.1em) scale(.75) !important;
    width: 133.33333% !important;
  }

  &.mat-form-field-appearance-outline {
    .mat-form-field-outline-start {
      border-radius: 8px 0 0 8px !important;
    }

    .mat-form-field-outline-end {
      border-radius: 0 8px 8px 0 !important;
    }

    .mat-form-field-wrapper {
      margin: 0 !important;
    }
  }

  .mat-select-arrow {
    border-left-width: 6px !important;
    border-right-width: 6px !important;
    border-top-width: 6px !important;
  }
}

.select-container {
  .filter-select {
    width: calc(25% - 9px);
  }
}

.role-name-input {
  &.mat-form-field-should-float {
    .mat-form-field-label-wrapper {
      display: none;
    }
  }
}

.dashboard-menu-item {
  &.mat-button-toggle {
    border-left: none !important;
  }

  &.mat-button-toggle-checked {
    background-color: white;
  }

  & span {
    width: 100%;
  }

  & * {
    color: rgba(0, 0, 0, 0.87);
  }
}

.pills {
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 -10px 0;
  display: table;

  .mat-chip-list-wrapper {
    margin: 0 !important;
  }

  &__pill {
    line-height: 28px !important;
    height: 28px;
    border-radius: $size-4 !important;
    padding: 0 $size-8 !important;
    margin: 0 10px 10px 0 !important;
    float: left;

    &--shimmer {
      padding: 0 !important;
    }

    &.mat-flat-button {
      color: $app-label;
      background-color: rgba($app-label, 0.2);

      &.pills__pill--selected {
        color: $primary;
        background-color: rgba($primary, 0.2);
      }
    }

    &.mat-stroked-button {
      font-size: 12px;
      color: $primary;
    }
  }
}

.accordion-pristine {
  box-shadow: none !important;

  .mat-expansion-panel-header {
    height: auto !important;

    &:hover {
      background: transparent !important;
    }

    &.mat-expansion-toggle-indicator-before .mat-expansion-indicator {
      padding: 0 2px;
      margin-right: 14px;
    }
  }

  .mat-expansion-indicator::after {
    display: block;
  }

  ;

  .mat-expansion-panel-body {
    padding: 0;
  }
}

.file-button {
  height: 100%;
  position: relative;
  border: 1px solid $app-light-grey;

  button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.mat-dialog-container {
  border-radius: $size-8 !important;
}

.link-decoration {
  text-decoration: underline;
  cursor: pointer;
}

.button-like-link {
  line-height: 14px !important;
  padding: 0 !important;
  margin: 12px 0 !important;
}

.sm-hide {
  display: block;

  @media (min-width: 960px) {
    display: none;
  }
}

app-role-dialog {
  .mat-form-field-underline {
    display: none;
  }
}

.file-add {
  position: relative;

  @media (min-width: 768px) {
    width: 37.5%;
  }

  .add {
    position: absolute;
    top: 20px;
    right: 0;
  }
}

.dynamic-table tr.list-element-row {
  height: $size-32 * 2 !important;
  position: relative;

  .drag-icon {
    color: $color-neutral-40;
    font-size: 16px;
    top: 24px;
    left: 0px;
    position: absolute;
    display: none;
  }

  td:first-child {
    padding-left: 16px !important;
  }

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 20px;
  }

  &:hover {
    background: white;
  }
}

.dynamic-table__hover {
  tr.list-element-row {
    &:hover {
      background: rgba($color-primary-00, 0.03);
      cursor: pointer;

      .action-background {
        display: block;
      }
    }
  }
}

.dynamic-table__cdk-drag tr.list-element-row {
  &:hover td:first-child .mat-icon {
    display: block !important;
  }

  &:active {
    cursor: grabbing;
  }
}

.cdk-drag-preview {
  background: #F7FBFE !important;
  border-top: 1px solid $color-neutral-20;
  border-bottom: 1px solid $color-neutral-20;

  td {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    border-bottom: none !important;

    &:first-child {
      padding-left: 10px !important;

      .drag-icon {
        display: block !important;
      }
    }
  }

  &:active {
    cursor: grabbing;
  }

  .action-background {
    display: block;
  }
}

.cdk-drop-list-dragging {
  cursor: grabbing !important;
}

.list-column-action {
  width: 0;
}

.action-background {
  background: linear-gradient(
    90deg,
    rgba(#f8f8ff, 0) 0px,
    rgba(#f8f8ff, 1) 100px
  );
  width: 268px;
  height: calc(100% - 1px);
  position: absolute;
  top: 0;
  right: 8px;
  display: none;
}

.superimposed-wrapper {
  margin-top: -14px;
  position: absolute;
  top: 50%;
  right: 0;
  display: flex;
  gap: 8px;
}

.superimposed-button {
  color: $color-neutral-60 !important;
  font-size: $font-size-13 !important;
  line-height: $size-14 !important;
  background: white !important;
  padding: 6px !important;
  border-radius: $size-4 !important;
  border-color: $color-neutral-05 !important;
  display: flex;
  gap: 4px;
  cursor: default !important;

  &[mat-stroked-button] {
    color: $color-primary-00 !important;
    border-width: 1px;
    border-color: $color-primary-00 !important;
  }

  &.color-neutral {
    color: $color-neutral-60 !important;
    border-color: $color-neutral-20 !important;
  }

  mat-icon {
    font-size: $size-14 !important;
    width: $size-14 !important;
    height: $size-14 !important;
  }

  &.mat-button-disabled {
    opacity: 0.7 !important;
  }

  &:hover:not(.mat-button-disabled) {
    background: lighten($color-primary-00, 40%) !important;
    color: $color-primary-00 !important;
    border-color: $color-primary-00 !important;
    cursor: pointer !important;
  }

  &:hover:not([mat-stroked-button]) {
    border-color: lighten($color-primary-00, 40%) !important;
  }
}

.list-tooltip {
  @at-root div.mat-tooltip-panel {
    pointer-events: none !important;
    cursor: default !important;
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mat-spinner[color="primary"] circle {
  stroke: $color-primary-00 !important;
}

.icon-button-with-loading {
  .custom-spinner-disabled mat-progress-spinner circle, .mat-spinner circle {
    stroke: white;
  }
}

.validation-retry-dialog-loading {
  padding: 47px 0;
  text-align: center;
  mat-spinner {
    display: inline-block;
    circle {
      stroke: $color-primary-00;
    }
  }
}

.file-comparison-dialog-backdrop {
  background: rgba(0, 0, 0, 0.6);
  height: 100vh;
}

.file-comparison-dialog-panel {
  height: calc(100vh - 80px);
  position: relative !important;
  top: 0;
}

@media (min-width: 960px) {
  .file-comparison-dialog-backdrop {
    width: calc(100vw - 160px);
    left: 160px !important;
  }

  .file-comparison-dialog-panel {
    width: calc(100vw - 240px);
    left: 80px;
  }
}

@media (max-width: 959px) {
  .file-comparison-dialog-backdrop {
    width: 100vw;
    left: 0;
  }

  .file-comparison-dialog-panel {
    width: calc(100vw - 80px);
    left: 0;
  }
}

.mat-button-toggle:not(.mat-button-toggle-checked):hover {
  background: rgba(19, 8, 254, 0.08);
}

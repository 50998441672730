@import 'tokens';
@import 'mixins';

//Bootstrap extension
//https://stackoverflow.com/a/62676042
.position-relative { position: relative; }
.position-absolute { position: absolute; }

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$positions: (
  static   : static,
  absolute : absolute,
  relative : relative,
  fixed    : fixed,
  sticky   : sticky,
);

@media (min-width: 576px) {
  .position-sm-static {
    position: static !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
  .position-sm-sticky {
    position: sticky !important;
  }
}

@media (min-width: 768px) {
  .position-md-static {
    position: static !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
  .position-md-sticky {
    position: sticky !important;
  }
}

@media (min-width: 992px) {
  .position-lg-static {
    position: static !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-sticky {
    position: sticky !important;
  }
}

@media (min-width: 1200px) {
  .position-xl-static {
    position: static !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-sticky {
    position: sticky !important;
  }
}

// Font Sizes
.font-size-48 { font-size: $font-size-48; }
.font-size-40 { font-size: $font-size-40; }
.font-size-32 { font-size: $font-size-32; }
.font-size-24 { font-size: $font-size-24; }
.font-size-20 { font-size: $font-size-20; }
.font-size-18 { font-size: $font-size-18; }
.font-size-16 { font-size: $font-size-16; }
.font-size-14 { font-size: $font-size-14; }
.font-size-13 { font-size: $font-size-13; }
.font-size-12 { font-size: $font-size-12; }
.font-size-10 { font-size: $font-size-10; }
.font-size-0 { font-size: $font-size-0; }

// Line Height
.line-height-22 { line-height: $font-size-22; }
.line-height-20 { line-height: $font-size-20; }
.line-height-16 { line-height: $font-size-16; }
.line-height-14 { line-height: $font-size-14; }
.line-height-13 { line-height: $font-size-13; }

// Colors
.color-success-dark { color: $color-success-dark; }
.color-error-dark { color: $color-error-dark; }
.color-primary-00 { color: $color-primary-00; }
.color-neutral-40 { color: $color-neutral-40; }
.color-neutral-60 { color: $color-neutral-60; }
.color-neutral-80 { color: $color-neutral-80; }

// Font Weights
.font-weight-900 { font-weight: $font-weight-900; }
.font-weight-700 { font-weight: $font-weight-700; }
.font-weight-500 { font-weight: $font-weight-500; }
.font-weight-400 { font-weight: $font-weight-400; }

// Shadows
.box-shadow-soft {
    box-shadow: $box-shadow-soft;
}
.box-shadow-medium {
    box-shadow: $box-shadow-medium;
}
.box-shadow-strong {
    box-shadow: $box-shadow-strong;
}

// Gradients
.gradient-brand {
    background: $gradient-brand;
}

// Paddings
.p-4px { padding: $size-4; }
.pt-4px { padding-top: $size-4; }
.pb-4px { padding-bottom: $size-4; }
.pl-4px { padding-left: $size-4; }
.pr-4px { padding-right: $size-4; }
.px-4px {
  padding-left: $size-4;
  padding-right: $size-4;
}
.py-4px {
  padding-top: $size-4;
  padding-bottom: $size-4;
}

.p-8 { padding: $size-8; }
.pt-8 { padding-top: $size-8; }
.pb-8 { padding-bottom: $size-8; }
.pl-8 { padding-left: $size-8; }
.pr-8 { padding-right: $size-8; }
.px-8 {
  padding-left: $size-8;
  padding-right: $size-8;
}
.py-8 {
  padding-top: $size-8;
  padding-bottom: $size-8;
}

.p-12 { padding: $size-12; }
.pt-12 { padding-top: $size-12; }
.pb-12 { padding-bottom: $size-12; }
.pl-12 { padding-left: $size-12; }
.pr-12 { padding-right: $size-12; }
.px-12 {
  padding-left: $size-12;
  padding-right: $size-12;
}
.py-12 {
  padding-top: $size-12;
  padding-bottom: $size-12;
}

.p-16 { padding: $size-16; }
.pt-16 { padding-top: $size-16; }
.pb-16 { padding-bottom: $size-16; }
.pl-16 { padding-left: $size-16; }
.pr-16 { padding-right: $size-16; }
.px-16 {
  padding-left: $size-16;
  padding-right: $size-16;
}
.py-16 {
  padding-top: $size-16;
  padding-bottom: $size-16;
}

.p-24 { padding: $size-24; }
.pt-24 { padding-top: $size-24; }
.pb-24 { padding-bottom: $size-24; }
.pl-24 { padding-left: $size-24; }
.pr-24 { padding-right: $size-24; }
.px-24 {
  padding-left: $size-24;
  padding-right: $size-24;
}
.py-24 {
  padding-top: $size-24;
  padding-bottom: $size-24;
}

.p-32 { padding: $size-32; }
.pt-32 { padding-top: $size-32; }
.pb-32 { padding-bottom: $size-32; }
.pl-32 { padding-left: $size-32; }
.pr-32 { padding-right: $size-32; }
.px-32 {
  padding-left: $size-32;
  padding-right: $size-32;
}
.py-32 {
  padding-top: $size-32;
  padding-bottom: $size-32;
}

// Margins
.m-4px { margin: $size-4; }
.mt-4px { margin-top: $size-4; }
.mb-4px { margin-bottom: $size-4; }
.ml-4px { margin-left: $size-4; }
.mr-4px { margin-right: $size-4; }
.mx-4px {
  margin-left: $size-4;
  margin-right: $size-4;
}
.my-4px {
  margin-top: $size-4;
  margin-bottom: $size-4;
}

.m-8 { margin: $size-8; }
.mt-8 { margin-top: $size-8; }
.mb-8 { margin-bottom: $size-8; }
.ml-8 { margin-left: $size-8; }
.mr-8 { margin-right: $size-8; }
.mx-8 {
  margin-left: $size-8;
  margin-right: $size-8;
}
.my-8 {
  margin-top: $size-8;
  margin-bottom: $size-8;
}

.m-12 { margin: $size-12; }
.mt-12 { margin-top: $size-12; }
.mb-12 { margin-bottom: $size-12; }
.ml-12 { margin-left: $size-12; }
.mr-12 { margin-right: $size-12; }
.mx-12 {
  margin-left: $size-12;
  margin-right: $size-12;
}
.my-12 {
  margin-top: $size-12;
  margin-bottom: $size-12;
}

.m-16 { margin: $size-16; }
.mt-16 { margin-top: $size-16; }
.mb-16 { margin-bottom: $size-16; }
.ml-16 { margin-left: $size-16; }
.mr-16 { margin-right: $size-16; }
.mx-16 {
  margin-left: $size-16;
  margin-right: $size-16;
}
.my-16 {
  margin-top: $size-16;
  margin-bottom: $size-16;
}

.m-24 { margin: $size-24; }
.mt-24 { margin-top: $size-24; }
.mb-24 { margin-bottom: $size-24; }
.ml-24 { margin-left: $size-24; }
.mr-24 { margin-right: $size-24; }
.mx-24 {
  margin-left: $size-24;
  margin-right: $size-24;
}
.my-24 {
  margin-top: $size-24;
  margin-bottom: $size-24;
}

.m-32 { margin: $size-32; }
.mt-32 { margin-top: $size-32; }
.mb-32 { margin-bottom: $size-32; }
.ml-32 { margin-left: $size-32; }
.mr-32 { margin-right: $size-32; }
.mx-32 {
  margin-left: $size-32;
  margin-right: $size-32;
}
.my-32 {
  margin-top: $size-32;
  margin-bottom: $size-32;
}

// Border Radius
.border-radius-4 { border-radius: $size-4; }
.border-radius-8 { border-radius: $size-8; }
.border-radius-12 { border-radius: $size-12; }
.border-radius-16 { border-radius: $size-16; }
.border-radius-24 { border-radius: $size-24; }
.border-radius-32 { border-radius: $size-32; }

// Border Width
.border-width-1 { border-width: 1px; }

// Border Style
.border-style-solid { border-style: solid; }

// Border Color
.border-color-neutral-10 { border-color: $color-neutral-10; }

// Overflows
.overflow-y-auto { overflow-y: auto; }

// Flex
.gap-8 { gap: 8px; }
.gap-16 { gap: 16px; }
.gap-20 { gap: 20px; } // Deprecated - used only in sick-notes-old-form.component.html
.gap-32 { gap: 32px; }

// Misc
.text-align-center { text-align: center; }
.text-transform-uppercase { text-transform: uppercase };
.white-space-nowrap { white-space: nowrap; }
.border-none { border: none; }
.border-spacing-0 { border-spacing: 0 };
.box-sizing-border-box { box-sizing: border-box; }
.pointer-events-none { pointer-events: none; }
.horizontal-margin-fill-32 { @include horizontal-margin-fill(32px); }
.table-layout-fixed { table-layout: fixed; }
.list-style-none { list-style: none; }
